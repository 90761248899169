// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/trail/trail.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/trail/trail.tsx");
  import.meta.hot.lastModified = "1701432125158.7913";
}
// REMIX HMR END

import React from 'react';
import { useTrail, a } from '@react-spring/web';
const Trail = ({
  children
}) => {
  _s();
  const open = true;
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: {
      mass: 1,
      tension: 600,
      friction: 60
    },
    opacity: open ? 1 : 0,
    x: open ? 0 : 0,
    height: open ? 100 : 0,
    from: {
      opacity: 0,
      x: 0,
      height: 0
    }
  });
  return <div>
    {trail.map(({
      height,
      ...style
    }, index) => <a.div key={index} className="relative w-full overflow-hidden will-change-transform will-change-opacity" style={style}>
        <a.div className="pr-[0.05em] overflow-hidden" style={{
        height
      }}>{items[index]}</a.div>
      </a.div>)}
  </div>;
};
_s(Trail, "K6MPM4NNUf2NQipcqSVMWb0gIKk=", false, function () {
  return [useTrail];
});
_c = Trail;
export default Trail;
var _c;
$RefreshReg$(_c, "Trail");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;
// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1709038524753.7402";
}
// REMIX HMR END

// Core
import { useEffect, useRef, useState } from "react";
import { Form, useActionData, useSearchParams } from "@remix-run/react";
import { useNavigation } from "react-router-dom";
import { json, redirect } from "@remix-run/node";
import { verifyLogin } from "~/models/user.server";
import { createUserSession, getUserId } from "~/session.server";

// Components
import Trail from "../components/common/trail/trail";
import ErrorPage from "~/components/common/error/error";

// Utils
import { validateEmail } from "~/utils/utils-validate";

// Types

// Ux
import { RxEyeClosed } from "react-icons/rx";
import { ImEye } from "react-icons/im";
import GlanumBanner from "~/components/common/glanum-banner/glanum-banner";
export const loader = async ({
  request
}) => {
  const userId = await getUserId(request);
  if (userId) return redirect("/");
  return json({});
};
export const action = async ({
  request
}) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  let redirectTo = "/client/project/dashboard";
  const remember = formData.get("remember");
  if (!validateEmail(email)) {
    return json({
      errors: {
        email: "Email est invalide",
        password: null
      }
    }, {
      status: 400
    });
  }
  if (typeof password !== "string" || password.length === 0) {
    return json({
      errors: {
        email: null,
        password: "Mot de passe requis"
      }
    }, {
      status: 400
    });
  }
  if (password.length < 4) {
    return json({
      errors: {
        email: null,
        password: "Mot de passe trop court"
      }
    }, {
      status: 400
    });
  }
  const {
    user,
    token
  } = await verifyLogin(email, password);
  if (!user) {
    return json({
      errors: {
        email: "Email ou mot de passe invalide",
        password: null
      }
    }, {
      status: 400
    });
  }
  if (user?.isAdmin) {
    redirectTo = "/admin/gestion-comptes";
  }
  return createUserSession({
    redirectTo,
    remember: remember === "on" ? true : false,
    request,
    user: user,
    token: token,
    userId: user?.user?.id?.toString() || user?.id?.toString()
  });
};
export const meta = () => [{
  title: "Login"
}];
export default function LoginPage() {
  _s();
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const redirectTo = searchParams.get("redirectTo") || "/admin/dashboard";
  const actionData = useActionData();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const navigation = useNavigation();
  useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);
  const loading = navigation.state === "idle" ? false : true;
  return <div className="flex min-h-full flex-col justify-center md:flex-row">
      <GlanumBanner imageUrl={"/images/glanum-statue.png"} imageAlt={"statue créative glanum"} vertical={true} />
      <div className="relative flex  h-full items-center justify-center md:h-auto md:flex-[40%]">
        <div className="mx-auto mt-[20px] flex h-full w-full max-w-lg flex-col items-center justify-center gap-[20px] overflow-hidden px-8 md:mt-0 md:gap-[40px]">
          <div className="">
            <img className="h-[70%] w-full" src="/images/glanum-logo.svg" alt="logo glanum" />
          </div>
          <Form method="post" className="h-[400px] w-full space-y-6 md:h-auto">
            <Trail>
              <div>
                <label htmlFor="email" className="glanum-label-text">
                  Identifiant
                </label>
                <div className="mt-1">
                  <input ref={emailRef} id="email" required autoFocus={true} name="email" type="email" autoComplete="email" placeholder="Entrez l'identifiant" aria-invalid={actionData?.errors?.email ? true : undefined} aria-describedby="email-error" className="input-text " />
                  {actionData?.errors?.email ? <div className="pt-1 text-red-700" id="email-error">
                      {actionData.errors.email}
                    </div> : null}
                </div>
              </div>

              <div>
                <label htmlFor="password" className="glanum-label-text">
                  Mot de passe
                </label>
                <div className="mt-1 relative">
                  <input id="password" ref={passwordRef} name="password" type={`${showPassword ? "text" : "password"}`} autoComplete="current-password" placeholder="Entrez le mot de passe" aria-invalid={actionData?.errors?.password ? true : undefined} aria-describedby="password-error" className="input-text" />
                  {showPassword ? <ImEye style={{
                  color: "#606060"
                }} onClick={() => setShowPassword(!showPassword)} className="absolute right-4 top-[50%] -translate-y-1/2 transform cursor-pointer select-none" /> : <RxEyeClosed style={{
                  color: "#606060"
                }} onClick={() => setShowPassword(!showPassword)} className="absolute right-4 top-[50%] -translate-y-1/2 transform cursor-pointer select-none" />}
                  {actionData?.errors?.password ? <div className="pt-1 text-red-700" id="password-error">
                      {actionData.errors.password}
                    </div> : null}
                </div>
              </div>
              <button type="submit" className="primary-button relative mt-[40px]" disabled={loading}>
                {loading ? <>
                    <span>Connexion</span>
                    <span className="dot-loading m-0 p-0"> ...</span>
                  </> : <span>Connexion</span>}
              </button>
              <input type="hidden" name="redirectTo" value={redirectTo} />
            </Trail>
          </Form>
        </div>

        <img className=" absolute bottom-0 right-0  w-[35%] object-contain object-bottom" src="/images/glanum-carre.png" alt="cubes glanum" />
      </div>
    </div>;
}
_s(LoginPage, "nlsf+ULrubebIbFzceOID56QJoE=", false, function () {
  return [useSearchParams, useActionData, useNavigation];
});
_c = LoginPage;
export function ErrorBoundary() {
  return <ErrorPage />;
}
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "LoginPage");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;